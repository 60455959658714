<template>
  <div id="signInDetails">
    <van-nav-bar title="订单详情" left-arrow @click-left="goLastPage" :border="false" />
    <ProductInfor  buttonText="马上去签到" @buttonClick="goSubPage('sign-in-position',$route.params.orderId )" request_url="/clock_in"/>
  </div>
</template>
<script>
import ProductInfor from "@/components/order/ProductInfor.vue";
export default {
  data() {
    return {};
  },
  components: {
    ProductInfor,
  },
  methods: {
    getVisitTime(e) {
      console.log(e);
      this.visit_time = e;
    },
  },
};
</script>
<style lang="less">
</style>